////
/// @group _settings/
////


/// Карта Шрифтов
/// @type {Map}
$font-family: (
	main: #{"OpenSans", Helvetica, Arial, sans-serif},
	form: sans-serif,
	sub: sans-serif,
	monospace: #{Consolas, "Liberation Mono", Menlo, Courier, monospace}
);
